import React from 'react'
import HomeHero from './hero'
import HomeShop from './shop'
import HomeFeatured from './featured'

const Home = () => {
  return (
    <>
      <HomeHero />
      <HomeShop />
      <HomeFeatured />
    </>
  )
}

export default Home
