import React, { useContext } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { HeadingText, BodyText, Button } from '../../styles'
import media from '../../styles/media'
import StoreContext from '~/context/StoreContext'

const HeroCon = styled.div`
  height: 550px;
  overflow: hidden;
  .fade-in-section {
    opacity: 0;
    transform: translateY(2vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  .hero-image {
    position: absolute !important;
    overflow: hidden;
    width: 100%;
    height: 550px;
    z-index: -1;
    picture {
      img {
        width: 100vw !important;
        object-position: -25em 0em !important;
      }
    }
  }
  .text {
    margin: calc(var(--gutter-m) + 40px) var(--gutter-s);
  }
  ${media.mobileL`
    height: 700px;
    .hero-image {
      position: absolute !important;
      overflow: hidden;
      width: 100vw;
      height: 700px;
      z-index: -1;
      picture {
        img {
          object-position: 0em 0em !important;
        }
      }
    }
    .text {
      margin: calc(var(--gutter-button) + 40px) var(--gutter-s);
    }
  `}
  ${media.laptop`
    height: 100vh;
    .hero-image {
      position: absolute !important;
      overflow: hidden;
      width: 100%;
      height: 100vh;
      z-index: -1;
      picture {
        img {
          object-position: 0em 0em;
        }
      }
    }
    .text {
      margin: var(--gutter-xxl) var(--gutter-m);
    }
  `}
`

const HomeFeatured = () => {
  const data = useStaticQuery(graphql`
    query {
      homeImg: file(relativePath: { eq: "home3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const FadeInSection = props => {
    const [isVisible, setVisible] = React.useState(false)
    const domRef = React.useRef()
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        for (const entry of entries) {
          if (entry.intersectionRatio > 0) {
            setVisible(entry.isIntersecting)
          }
        }
      })

      if (domRef.current.classList.contains('is-visible')) {
        observer.unobserve(domRef.current)
      } else {
        observer.observe(domRef.current)
      }
    }, [])

    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    )
  }

  return (
    <>
      <HeroCon>
        <Img
          durationFadeIn={250}
          objectFit="cover"
          fluid={data.homeImg.childImageSharp.fluid}
          className="hero-image"
        />

        <FadeInSection>
          <div className="text">
            <HeadingText>
              Original HP Ink Cartridges
            </HeadingText>
            <BodyText className="smallWidth">
              Create high-quality colour photos and everyday documents with
              Original HP Ink cartridges. These dependable cartridges deliver
              consistent results, so you can print vivid colour and crisp text for
              the life of your cartridge.
            </BodyText>
            <Button onClick={() => navigate('/products')} className="top-space">Shop Now</Button>
          </div>
        </FadeInSection>
      </HeroCon>
    </>
  )
}

export default HomeFeatured
