import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { HeadingText, BodyText } from '../../styles'
import media from '../../styles/media'

const ShopCon = styled.div`
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  .fade-in-section {
    opacity: 0;
    transform: translateY(2vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  .shop-image {
    position: absolute !important;
    overflow: hidden;
    width: 100%;
    height: 550px;
    z-index: -1;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--gutter-l);
    button {
      margin: var(--gutter-button) auto;
    }
  }
  ${media.mobileL`
    height: 100vh;
    .shop-image {
      width: 100vw;
      height: 100vh;
    }
  `}
  ${media.laptop`
    height: 100vh;
    margin: var(--gutter-s);
    .shop-image {
      position: absolute !important;
      overflow: hidden;
      width: calc(100% - (var(--gutter-s) * 2));
      height: 100vh;
      z-index: -1;
    }
    .text {
      margin-top: var(--gutter-xxl);
    }
  `}
`

const HomeShop = () => {
  const data = useStaticQuery(graphql`
    query {
      shopImg: file(relativePath: { eq: "home2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const FadeInSection = props => {
    const [isVisible, setVisible] = React.useState(false)
    const domRef = React.useRef()
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        for (const entry of entries) {
          if (entry.intersectionRatio > 0) {
            setVisible(entry.isIntersecting)
          }
        }
      })

      if (domRef.current.classList.contains('is-visible')) {
        observer.unobserve(domRef.current)
      } else {
        observer.observe(domRef.current)
      }
    }, [])
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    )
  }

  return (
    <ShopCon>
      <Img
        durationFadeIn={250}
        objectFit="cover"
        fluid={data.shopImg.childImageSharp.fluid}
        className="shop-image"
      />
      <FadeInSection>
        <div className="text">
          <HeadingText className="center">Design That Counts</HeadingText>

          <BodyText className="midText center">
            Original HP toner &amp; ink cartridges are designed with your HP
            printers, to produce the best results, each and every time.
          </BodyText>
        </div>
      </FadeInSection>
    </ShopCon>
  )
}

export default HomeShop
