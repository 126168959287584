import React from 'react'

import SEO from '~/components/seo'
import Home from '../components/Home'
import Footer from '../components/Footer'

const IndexPage = () => (
  <>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Home />
    <Footer />
  </>
)

export default IndexPage
